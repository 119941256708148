// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-about-plantation-js": () => import("./../../../src/pages/about/plantation.js" /* webpackChunkName: "component---src-pages-about-plantation-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-online-js": () => import("./../../../src/pages/online.js" /* webpackChunkName: "component---src-pages-online-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-schedule-index-js": () => import("./../../../src/pages/schedule/index.js" /* webpackChunkName: "component---src-pages-schedule-index-js" */),
  "component---src-pages-schedule-studios-index-js": () => import("./../../../src/pages/schedule/studios/index.js" /* webpackChunkName: "component---src-pages-schedule-studios-index-js" */),
  "component---src-pages-teachers-js": () => import("./../../../src/pages/teachers.js" /* webpackChunkName: "component---src-pages-teachers-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */)
}

