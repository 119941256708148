import styled from "styled-components"
import { Link } from "gatsby"
import { device } from "../../utils/responsive"
import Logo from "../logo/logo.component"

export const BurgerLogo = styled(Logo)`
  width: 50%;
  max-width: 200px;
  object-fit: contain;
`

export const BurgerNavContainer = styled.div`
  height: 100px;
  width: 100%;
  background: white;
  display: flex;
  align-items: center;
  justify-content: space-around;
  @media ${device.mobileS} {
  }
  @media ${device.mobileM} {
  }
  @media ${device.mobileL} {
  }
  @media ${device.mobileXL} {
  }
  @media ${device.tablet} {
    display: none;
  }
  @media ${device.laptop} {
  }
  @media ${device.laptopL} {
  }
`

export const BurgerHeaderContainer = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  //width: 100;
  margin: 0 auto;
  position: sticky;
  top: 0;
  font-size: 3rem;
  //margin: 4rem 0;
  z-index: 9999;
  box-shadow: ${({ shouldShowShadow }) => {
    if (shouldShowShadow) {
      return " 0 9px 9px -9px rgba(0, 0, 0, 0.13);"
    } else {
      return "none"
    }
  }};

  transform: translateY(
    ${({ shouldHideHeader, theme }) =>
      shouldHideHeader ? "-" + theme.spacing["16"] : "0px"}
  );
  transition: all 0.6s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;

  & ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    letter-spacing: 0.3rem;
    //width: 90vw;
    //flex-wrap: wrap;

    & li {
      margin-top: 3rem;
    }
  }
`
export const BurgerHeaderLink = styled(Link)`
  color: ${({ theme }) => theme.colours.black};
  text-decoration: none;
  font-size: ${({ theme }) => theme.fontSize["2xl"]};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  padding: 0.3rem 0;
  position: relative;
  &:after,
  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 100%;
    left: -2%;
    width: 100%;
    height: 2px;
    background-color: black;
    transition: all 0.3s ease;
    transform: scale(0);
  }

  &:hover:before {
    top: 0;
    transform: scale(1);
  }

  &:hover:after {
    transform: scale(1);
  }

  &.active {
    &:after,
    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: black;
      transition: all 0.3s ease;
      transform: scale(1);
    }

    &:before {
      top: 0;
      transform: scale(0);
    }

    &:after {
      transform: scale(1);
    }
  }
`
