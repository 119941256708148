import { MDXRenderer } from "gatsby-plugin-mdx"
import React from "react"
import {
  NameAndPictureContainer,
  ProfileImage,
  ProfileImageContainer,
  SingleTeamMemberContainer,
  Summary,
  AvatarAnimLight,
} from "./single-team-member.styles"

function SingleTeamMember({ name, picture, summary, index }) {
  return (
    <SingleTeamMemberContainer>
      <NameAndPictureContainer>
        <ProfileImageContainer>
          <AvatarAnimLight>
            <ProfileImage alt={name} src={picture} />
          </AvatarAnimLight>
        </ProfileImageContainer>
      </NameAndPictureContainer>
      <Summary>
        <MDXRenderer>{summary}</MDXRenderer>
      </Summary>
    </SingleTeamMemberContainer>
  )
}

export default React.memo(SingleTeamMember)
