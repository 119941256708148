import styled from "styled-components"
import { device } from "../../utils/responsive"

export const Instructions = styled.div`
  text-align: justify;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  //margin: 2rem;

  padding: 3rem;
  background: whitesmoke;

  font-family: ${({ theme }) => theme.font.Lato};
  & ul > li {
    position: relative;
    list-style-type: none;
    padding-left: 2.5rem;
    margin-bottom: 0.5rem;
    /*  &:nth-child(1){
      font-weight: 600;
    }
   &:nth-child(1):before {
      content: "!";
    font-size: 1.3rem;
    animation: color-change 3s ease-in-out infinite;
    display: flex;
    position: absolute;
    left: -10px;
    top: -5px;
    width: 40px;
    border-radius: 50%;
    height: 40px;
    background: #8080802e;
    text-align: center;
    border: 2px solid;
    justify-content: center;
    align-items: center;
    @keyframes color-change {
      50% {
        color: red;
      }
    }
          } */

      :before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: -2px;
      width: 10px;
      height: 21px;
      border-width: 0 2px 2px 0;
      border-style: solid;
      border-color: #00a8a8;
      transform-origin: bottom left;
      transform: rotate(45deg);
    }


   }
  }

  @media ${device.mobileS} {
  }
  @media ${device.mobileM} {
  }
  @media ${device.mobileL} {
  }
  @media ${device.mobileXL} {
    padding-top: 0;
    padding-bottom: 0;
    background: white;
  }
  @media ${device.tablet} {
    width:50%;
  }
  @media ${device.laptop} {
  }
  @media ${device.laptopL} {
  }
`
