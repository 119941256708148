import styled from "styled-components"

export const P = styled.p`
  font-family: ${({ theme }) => theme.font.Lato};
  margin: ${({ theme }) => theme.spacing[3]} 0;
  strong {
    font-weight: bold;
  }
  em {
    font-style: italic;
  }
`
