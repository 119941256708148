import { Container, Text, Mission } from "../../../../src/pages/about/about.styles";
import { STRONG } from "../../../../src/components/page-elements";
import { CardContainer, StyledImg, Title, CardImgContainer, TextOverImg, Subtitle } from "../../../../src/components/card/card.styles.js";
import * as React from 'react';
export default {
  Container,
  Text,
  Mission,
  STRONG,
  CardContainer,
  StyledImg,
  Title,
  CardImgContainer,
  TextOverImg,
  Subtitle,
  React
};