import React, { useState } from "react"

import Burger from "@animated-burgers/burger-slip"
import "@animated-burgers/burger-slip/dist/styles.css"
import "./burger-nav.styles.css"
import { bubble as Menu } from "react-burger-menu"
import { Link } from "gatsby"
//import { HeaderContainer, HeaderLink } from "../header/header.styles"
import {
  BurgerHeaderContainer,
  BurgerHeaderLink,
  BurgerLogo,
  BurgerNavContainer,
} from "./burger-nav.styles"
import { useCallback } from "react"
import CustomButton from "../custom-button/custom-button.component"

function BurgerNav(props) {
  const [isOpen, setIsOpen] = useState(false)

  const closeDrawer = useCallback(() => {
    setIsOpen(false)
  }, [])

  return (
    <>
      <Menu
        pageWrapId={"page-wrap"}
        isOpen={isOpen}
        width={250}
        onClose={() => {
          setIsOpen(false)
        }}
        onStateChange={state => {
          setIsOpen(state.isOpen)
        }}
        customBurgerIcon={false}
        disableAutoFocus
      >
        <BurgerHeaderContainer>
          <ul>
            <li>
              <BurgerHeaderLink
                activeClassName="active"
                to="/"
                onClick={closeDrawer}
              >
                Home
              </BurgerHeaderLink>
            </li>
            <li>
              <BurgerHeaderLink
                activeClassName="active"
                to="/teachers"
                onClick={closeDrawer}
              >
                Teachers
              </BurgerHeaderLink>
            </li>
            <li>
              <BurgerHeaderLink
                activeClassName="active"
                to="/schedule"
                onClick={closeDrawer}
              >
                Schedule
              </BurgerHeaderLink>
            </li>
            <li>
              <BurgerHeaderLink
                activeClassName="active"
                to="/about"
                onClick={closeDrawer}
              >
                About
              </BurgerHeaderLink>
            </li>

            <li>
              <CustomButton
                href="https://app.greenyoga.co/signin"
                inverted={true}
                isInHeader={true}
                onClick={() => {
                  window.open("https://app.greenyoga.co/signin", "_blank")
                }}
              >
                Login
              </CustomButton>
            </li>
          </ul>
        </BurgerHeaderContainer>
      </Menu>

      <BurgerNavContainer>
        <Burger isOpen={isOpen} onClick={() => setIsOpen(!isOpen)} />

        <Link to="/">
          <BurgerLogo alt="logo" />
        </Link>
      </BurgerNavContainer>

      {/* {!isSSR && (
        <React.Suspense fallback={<div />}>
          <DrawerModule.Drawer
            visible={isOpen}
            onClose={() => setIsOpen(false)}
          >
            <BurgerHeaderContainer>
              <ul>
                <li>
                  <BurgerHeaderLink
                    activeClassName="active"
                    to="/"
                    onClick={closeDrawer}
                  >
                    Home
                  </BurgerHeaderLink>
                </li>
                <li>
                  <BurgerHeaderLink
                    activeClassName="active"
                    to="/teachers"
                    onClick={closeDrawer}
                  >
                    Teachers
                  </BurgerHeaderLink>
                </li>
                <li>
                  <BurgerHeaderLink
                    activeClassName="active"
                    to="/classes"
                    onClick={closeDrawer}
                  >
                    Classes
                  </BurgerHeaderLink>
                </li>
                <li>
                  <BurgerHeaderLink
                    activeClassName="active"
                    to="/about"
                    onClick={closeDrawer}
                  >
                    About
                  </BurgerHeaderLink>
                </li>
              </ul>
            </BurgerHeaderContainer>
          </DrawerModule.Drawer>
        </React.Suspense>
      )} */}
    </>
  )
}

export default BurgerNav
