import Types from "./types"
const INITIAL_STATE = {
  loading: false,
  scheduleData: {},
  errorMsg: "",
  trainers: [],
  locations: [],
  classes: [],
  locationFilter: "Online Classes",
  classFilter: "All Styles",
  teacherFilter: "All Teachers",
  filteredResults: [],
  showSchedule: false,
  scheduleLocation: "Online",
  yogaStyles: [
    "All Styles",
    "Green Talks",
    "Vinyasa Yoga",
    "Gong Meditation",
    "Yoga + Meditation",
    "Hatha Yoga",
    "Hatha Flow",
    "Vinyasa Flow",
    "Power Vinyasa",
    "Ashtanga Vinyasa",
    "Gentle Flow",
    "Kundalini",
    "Iyengar",
    "Yoga Meets Dance",
    "Pilates",
    "Wake Up Yoga",
    "Yin Yoga",
    "Yin Yang",
    "Prenatal Yoga",
    "Yoga Nidra",
    "Mindful Stretching",
    "Hatha Tantra Yoga",
  ],
}

const Reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.FETCH_FITOGRAM_DATA_START:
      return { ...state, loading: true }
    case Types.FETCH_FITOGRAM_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        errorMsg: null,
        trainers: action.payload.Trainers,
        locations: action.payload.Locations,
      }
    case Types.GENERATING_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        errorMsg: "",
        classes: action.payload,
      }
    case Types.GENERATING_DATA_FAILURE:
    case Types.FETCH_FITOGRAM_DATA_FAILURE:
      return { ...state, loading: false, errrorMsg: action.payload }

    case Types.SET_LOCATION_FILTER:
      return { ...state, locationFilter: action.payload }
    case Types.SET_TEACHERS_FILTER:
      return { ...state, teacherFilter: action.payload }
    case Types.SET_CLASS_FILTER:
      return { ...state, classFilter: action.payload }
    case Types.FILTER_CLASSES_ERROR:
      return { ...state, errrorMsg: action.payload }
    case Types.FILTER_CLASSES_SUCCESS:
      return { ...state, filteredResults: action.payload }
    case Types.RESET_FILTER:
      return {
        ...state,
        filteredResults: [],
        classFilter: "All Styles",

        teacherFilter: "All Teachers",
      }
    case Types.SET_SHOW_SCHEDULE:
      return { ...state, showSchedule: action.payload }
    case Types.SET_YOGA_STYLES:
      return { ...state, yogaStyles: action.payload }
    case Types.SET_SCHEDULE_LOCATION:
      return { ...state, scheduleLocation: action.payload }
    default:
      return state
  }
}

export default Reducer
