import styled, { keyframes } from "styled-components"
import { H1 } from "../../components/page-elements"
import { device } from "../../utils/responsive"

const fadeIn = keyframes`
  from {
    transform: scale(.25);
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
`

const fadeOut = keyframes`
  from {
    transform: scale(1);
    opacity: 0;
  }

  to {
    transform: scale(.25);
    opacity: 1;
  }
`

export const StoryImage = styled.div`
  background-color: black;
  display: flex;
  position: relative;
  width: 100%;
`
export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media ${device.mobileS} {
  }
  @media ${device.mobileM} {
  }
  @media ${device.mobileL} {
  }
  @media ${device.mobileXL} {
  }
`

export const Story = styled.div`
  width: 70vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: ${({ theme }) => theme.spacing["16"]};
  background-color: #fffffe;
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.02),
    0 6.7px 5.3px rgba(0, 0, 0, 0.028), 0 12.5px 10px rgba(0, 0, 0, 0.035),
    0 22.3px 17.9px rgba(0, 0, 0, 0.042), 0 41.8px 33.4px rgba(0, 0, 0, 0.05),
    0 100px 80px rgba(0, 0, 0, 0.07);
  visibility: ${props => (!props.showStory ? "hidden" : "visible")};
  animation: ${props => (!props.showStory ? fadeOut : fadeIn)} 1s linear;
  transition: visibility 1s linear;
`

export const Text = styled.div`
  font-family: ${({ theme }) => theme.font.Lato};
  width: 100%;
  letter-spacing: 0.15rem;
  line-height: 2.5;
  padding: 4rem;
  @media ${device.laptopL} {
    padding: 6rem;
  }
`
export const CenterBold = styled.span`
  display: table;
  margin: 0 auto;
  font-weight: 600;
  text-align: center;
`
export const TextOverImg = styled.div`
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 99;
  width: 100%;
  text-align: center;
  text-shadow: 3px 3px rgba(0, 0, 0, 0.13);
  & > span:nth-child(2) {
    font-size: ${({ theme }) => theme.fontSize["xl"]};
    color: white;
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    letter-spacing: ${({ theme }) => theme.letterSpacing["3"]};
  }
  & > span:nth-child(3) {
    font-size: ${({ theme }) => theme.fontSize["l"]};
    color: white;
    letter-spacing: 0.3rem;
    padding: 2rem;
    font-weight: 700;
    text-transform: uppercase;
  }
  button {
    margin: 2rem 0 4rem;
    color: red;
  }
`
export const Title = styled(H1)`
  text-transform: uppercase;
  color: white;
  font-weight: 600;
  letter-spacing: ${({ theme }) => theme.letterSpacing["20"]};
  font-size: 3.5rem;
`

export const Mission = styled.div`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-family: ${({ theme }) => theme.font.Montserrat};
  text-transform: uppercase;
  text-align: center;
  letter-spacing: ${({ theme }) => theme.letterSpacing["4"]};
  margin: 1rem 0 2rem 0 !important;
  display: flex;
  flex-direction: column;
`
