import styled from "styled-components"

export const H3 = styled.h3`
  font-size: ${({ theme }) => theme.fontSize["2xl"]};
  font-family: ${({ theme }) => theme.font.Montserrat};
  margin: ${({ theme }) => theme.spacing[4]} 0;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: ${({ theme }) => theme.letterSpacing["3"]};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  line-height: ${({ theme }) => theme.lineHeight.loose};
`
