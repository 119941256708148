import React, { useState } from "react"

import useDocumentScrollThrottled from "../../utils/useDocumentScrollThrottled"
import BurgerNav from "../burger-menu/burger-nav.component"
import CustomButton from "../custom-button/custom-button.component"
import {
  HeaderContainer,
  HeaderLink,
  HeaderLogo,
  LogoLink,
  LogoContainer,
} from "./header.styles"

const Header = () => {
  const [shouldHideHeader, setShouldHideHeader] = useState(false)
  const [shouldShowShadow, setShouldShowShadow] = useState(false)
  const MINIMUM_SCROLL = 80
  const TIMEOUT_DELAY = 0

  useDocumentScrollThrottled(callbackData => {
    const { previousScrollTop, currentScrollTop } = callbackData
    const isScrolledDown = previousScrollTop < currentScrollTop
    const isMinimumScrolled = currentScrollTop > MINIMUM_SCROLL

    setShouldShowShadow(currentScrollTop > MINIMUM_SCROLL)

    setTimeout(() => {
      setShouldHideHeader(isScrolledDown && isMinimumScrolled)
    }, TIMEOUT_DELAY)
  })

  return (
    <>
      <BurgerNav />
      <HeaderContainer
        shouldHideHeader={shouldHideHeader}
        shouldShowShadow={shouldShowShadow}
      >
        <ul>
          <LogoContainer>
            <LogoLink to="/">
              <HeaderLogo alt="logo" />
            </LogoLink>
          </LogoContainer>
          <li>
            <HeaderLink activeClassName="active" to="/">
              Home
            </HeaderLink>
          </li>
          <li>
            <HeaderLink activeClassName="active" to="/teachers">
              Teachers
            </HeaderLink>
          </li>
          <li>
            <HeaderLink activeClassName="active" to="/schedule">
              Schedule
            </HeaderLink>
          </li>
          <li>
            <HeaderLink activeClassName="active" to="/about">
              About
            </HeaderLink>
          </li>

          <li>
            <CustomButton
              href="https://app.greenyoga.co/signin"
              inverted={true}
              isInHeader={true}
              onClick={() => {
                window.open("https://app.greenyoga.co/signin", "_blank")
              }}
            >
              Login
            </CustomButton>
          </li>
        </ul>
      </HeaderContainer>
    </>
  )
}

export default Header
