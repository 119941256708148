import React from "react"
import SingleTeamMember from "../single-team-member/single-team-member.component"

function TeamMembersList({ members }) {
  return (
    members && (
      <div>
        {members.map((member, index) => {
          return (
            member.frontmatter.featuredimage.src.publicURL !== null && (
              <SingleTeamMember
                key={String(index)}
                name={member.frontmatter.title}
                picture={member.frontmatter.featuredimage.src.publicURL}
                summary={member.body}
                index={index}
              />
            )
          )
        })}
      </div>
    )
  )
}

export default React.memo(TeamMembersList)
