import styled, { css } from "styled-components"
import { device } from "../../utils/responsive"

const largeButtonStyles = css`
  padding: 0 35px 0 35px;
  height: ${({ theme }) => theme.spacing["16"]};
`
const smallButtonStyles = css`
  padding: 0 15px 0 15px;
  height: ${({ theme }) => theme.spacing["8"]};
`

const invertedButtonStyles = css`
  &:hover {
    border: 1px solid black;
    background-color: white;
    color: black;
  }
`

const getButtonStyles = ({ large }) => {
  return large ? largeButtonStyles : smallButtonStyles
}

export const CustomButtonContainer = styled.button`
  width: ${({ newsLetter = false }) => (newsLetter ? "100%" : "auto")};
  min-width: 160px;
  min-height: 50px;
  letter-spacing: 0.5px;
  letter-spacing: 0.3rem;
  font-family: ${({ theme }) => theme.font.Lato};
  font-size: ${({ theme }) => theme.fontSize["base"]};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  text-transform: uppercase;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colours.darkgreen};
  color: white;
  border: none;
  border-radius: 4px;
  margin: ${({ theme, isInHeader = false }) =>
    isInHeader ? "0 0 1rem 0" : `${theme.spacing[6]} 0`};
  ${getButtonStyles}
  ${({ inverted }) => (inverted ? invertedButtonStyles : null)}
  ${({ fullinvert }) => {
    return fullinvert
      ? ` border: 1px solid black;
    background-color: white;
    color: black;`
      : null
  }}
  transition: all 0.15s ease-in-out 0s;

  @media ${device.mobileS} {
    font-size: ${({ theme }) => theme.fontSize["xs"]};
  }
  @media ${device.mobileM} {
    font-size: ${({ theme }) => theme.fontSize["xs"]};
  }
  @media ${device.mobileL} {
    font-size: ${({ theme }) => theme.fontSize["xs"]};
  }
  @media ${device.mobileXL} {
    font-size: ${({ theme }) => theme.fontSize["sm"]};
  }
  @media ${device.tablet} {
    font-size: ${({ theme }) => theme.fontSize["sm"]};
    width: ${({ newsLetter = false }) => (newsLetter ? "50%" : "auto")};
  }
  @media ${device.laptop} {
    font-size: ${({ theme }) => theme.fontSize["sm"]};
  }
  @media ${device.laptopL} {
    font-size: ${({ theme }) => theme.fontSize["sm"]};
  }
`
