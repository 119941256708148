import { createGlobalStyle } from "styled-components"
import reset from "styled-reset"
import { device } from "../utils/responsive"
export const theme = {
  headerHeight: "45vh",
  breakPoints: {
    xxs: "320px",
    xs: "425px",
    xm: "580px",
    sm: "667px",
    md: "992px",
    lg: "1312px",
    xl: "1500px",
    xxl: "1920px",
  },
  colours: {
    transparent: "transparent",

    darkgreen: "#001e1d",
    green: "#004643",
    lightgreen: "#abd1c6",
    black: "#333",
    white: "#fffffe",
    logoLeaf: "#8ad087",

    grey: {
      100: "#f7fafc",
      200: "#edf2f7",
      300: "#e2e8f0",
      400: "#cbd5e0",
      500: "#a0aec0",
      600: "#718096",
      700: "#4a5568",
      800: "#2d3748",
      900: "#1a202c",
    },
  },
  font: {
    Lato: "Lato",
    Montserrat: " Montserrat",
  },
  fontSize: {
    xxs: "0.6rem",
    xs: "0.75rem",
    sm: "0.875rem",
    base: "1rem",
    lg: "1.125rem",
    xl: "1.25rem",
    "2xl": "1.5rem",
    "3xl": "1.875rem",
    "4xl": "2.25rem",
    "5xl": "3rem",
    "6xl": "4rem",
  },
  fontWeight: {
    hairline: "100",
    thin: "200",
    light: "300",
    normal: "400",
    medium: "500",
    semibold: "600",
    bold: "700",
    extrabold: "800",
    black: "900",
  },
  letterSpacing: {
    tighter: "-0.05em",
    tight: "-0.025em",
    normal: "0",
    wide: "0.025em",
    wider: "0.05em",
    widest: "0.1em",
    "3": ".3rem",
    "4": ".4rem",
    "5": ".5rem",
    "10": "1rem",
    "20": "2rem",
  },
  spacing: {
    px: "1px",
    "0": "0",
    "1": "0.25rem",
    "2": "0.5rem",
    "3": "0.75rem",
    "4": "1rem",
    "5": "1.25rem",
    "6": "1.5rem",
    "8": "2rem",
    "10": "2.5rem",
    "12": "3rem",
    "16": "4rem",
    "20": "5rem",
    "24": "6rem",
    "32": "8rem",
    "40": "10rem",
    "48": "12rem",
    "56": "14rem",
    "64": "16rem",
  },
  lineHeight: {
    none: "1",
    tight: "1.25",
    snug: "1.375",
    normal: "1.5",
    relaxed: "1.625",
    loose: "2",
  },
  mediaQueries: breakPoint => style =>
    `@media (max-width: ${theme.breakPoints[breakPoint]}) { ${style} }`,
}

export const GlobalStyle = createGlobalStyle`
  ${reset}

  *, *:before, *:after {
    box-sizing: border-box;
  }
  html {

    box-sizing: border-box;
    scroll-behavior: smooth;
    box-sizing: border-box;
    scroll-behavior: smooth;
    box-sizing: border-box;
    scroll-behavior: smooth;
    font-family: ${({ theme }) => theme.font.Montserrat};
    font-size: ${({ theme }) => theme.fontSize.xs};
    color: ${({ theme }) => theme.colours.darkgreen};
    background-color:${({ theme }) => theme.colours.white};

    @media (min-width: 1800px) {
       font-size:1.7rem;
     }

    @media ${device.tablet}{ font-size: ${({ theme }) => theme.fontSize.base} }
    @media ${device.laptop}{ font-size: ${({ theme }) => theme.fontSize.xl} }





  }
  body {
    line-height: ${({ theme }) => theme.lineHeight.relaxed};
    letter-spacing: ${({ theme }) => theme.letterSpacing.wide};
    background-color: ${({ theme }) => theme.colours.white};
    overflow: hidden;
    position:relative;
  }
  ul{
    list-style:disc
  }
  blockquote{
    margin: 0;
    padding-left: 1.4rem;
    position: relative;


    &  p {

    padding: 15px;
    background: #eee;
    border-radius: 5px;
    }
  }
  strong{
    font-weight:600;
  }
  p {
    font-family:${theme.font.Lato};
  }
/*   table, th, td {
  border: 1px solid #d8d8d8;
  padding:.5rem;
}
th {
  font-weight:600;
}
tr:nth-child(odd)>td, .table-striped>tbody>tr:nth-child(odd)>th {
  background-color: #f9f9f9;
}
th:last-child, td:last-child {
    padding-right: .5rem;
}
td:first-child {
    padding-left: 0.5rem;
}
em {
  font-style: italic;
  font-weight:600;
} */
  `
