import { Container, Text, Mission, CenterBold } from "../../../../src/pages/about/about.styles";
import { STRONG } from "../../../../src/components/page-elements";
import { CardContainer, StyledImg, Title, CardImgContainer, TextOverImg, Subtitle, Intro } from "../../../../src/components/card/card.styles.js";
import TeamMembersList from "../../../../src/components/team-members-list/team-members-list.component";
import * as React from 'react';
export default {
  Container,
  Text,
  Mission,
  CenterBold,
  STRONG,
  CardContainer,
  StyledImg,
  Title,
  CardImgContainer,
  TextOverImg,
  Subtitle,
  Intro,
  TeamMembersList,
  React
};