import React from "react"
import Layout from "./src/components/layout"
import { ThemeProvider } from "styled-components"

import { GlobalStyle, theme } from "./src/theme/global-style"
import { Provider } from "react-redux"
import store from "./src/redux/store"

export const wrapPageElement = ({ element }) => (
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Layout>{element}</Layout>
    </ThemeProvider>
  </Provider>
)
