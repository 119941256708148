import styled from "styled-components"

export const StyledFooter = styled.footer`
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  font-size: ${({ theme }) => theme.fontSize.xs};
  margin-top: ${({ theme }) => theme.spacing["16"]};
  padding: ${({ theme }) => theme.spacing["4"]};
  background-color: #f9f9f9;
  border-top: 1px solid #e5e5e5;
`
export const GroupContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  width: 100%;
  justify-content: space-evenly;
  margin-bottom: ${({ theme }) => theme.spacing["4"]};
`
export const ItemGroup = styled.div`
  margin-left: ${({ theme }) => theme.spacing["8"]};
  ${({ theme }) => theme.mediaQueries("xs")(`   margin-left:0;`)}
`

export const Item = styled.a`
  color: ${({ theme }) => theme.colours.darkgreen};
  text-decoration: none;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: ${({ theme }) => theme.spacing["4"]};
  opacity: 0.8;
  transition: transform 250ms ease 0s;
  & :hover {
    transform: translateY(-2px);
    opacity: 1;
  }
`

export const StyledIcon = styled.div`
  width: ${({ width }) => (width ? width : "25px")};

  margin-right: 10px;
  ${({ theme }) =>
    theme.mediaQueries("xs")(
      ` width:20px;
  flex-shrink: 0;`
    )}
`

export const PrivacyContainer = styled.div`
  display: flex;

  justify-content: center;
`
export const PrivacyLink = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.colours.black};
  margin-right: 16px;
  :hover {
    color: ${({ theme }) => theme.colours.logoLeaf};
  }
`
