import styled, { keyframes } from "styled-components"
import { H1 } from "../../components/page-elements"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import { device } from "../../utils/responsive"

const fadeIn = keyframes`
from {
  transform: scale(.25);
  opacity: 0;
}

to {
  transform: scale(1);
  opacity: 1;
}
`

export const AboutUsImg = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "nora-sri.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <StyledImg
      fluid={data.placeholderImage.childImageSharp.fluid}
      alt="Founders of Green Yoga"
    />
  )
}

export const StyledImg = styled(Img)`
  width: 100%;
  height: 40vh;
  opacity: 0.7;

  @media ${device.mobileXL} {
    & > picture {
      & img {
        object-position: center 35% !important;
      }
    }
  }
  @media (min-width: 620px) {
    height: 50vh;
  }
  @media ${device.tablet} {
    height: 55vh;
  }
  @media ${device.laptop} {
    height: 70vh;
    & > picture {
      & img {
        object-position: center 30% !important;
      }
    }
  }
  @media ${device.laptopL} {
    height: 85vh;
  }
`

export const CardImgContainer = styled.div`
  background-color: black;
  display: flex;
  position: relative;
  width: 100%;
`

export const CardContainer = styled.div`
  width: 100%;
  box-shadow: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: ${fadeIn} 1s linear;
  @media ${device.tablet} {
    // TODO I changed this to add a 2 column in the grid
    width: 80vw;
    box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.02),
      0 6.7px 5.3px rgba(0, 0, 0, 0.028), 0 12.5px 10px rgba(0, 0, 0, 0.035),
      0 22.3px 17.9px rgba(0, 0, 0, 0.042), 0 41.8px 33.4px rgba(0, 0, 0, 0.05),
      0 100px 80px rgba(0, 0, 0, 0.07);
  }
`
export const Text = styled.div`
  text-align: justify;
  letter-spacing: 0.15rem;
  line-height: 2.5;
  padding: 2rem;
`

export const TextOverImg = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 99;
  width: 100%;
  text-align: center;
  text-shadow: 3px 3px rgba(0, 0, 0, 0.13);
`
export const Subtitle = styled.span`
  font-size: ${({ theme }) => theme.fontSize["xl"]};
  color: white;
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  letter-spacing: ${({ theme }) => theme.letterSpacing["3"]};
`
export const Intro = styled.span`
  font-size: ${({ theme }) => theme.fontSize["l"]};
  color: white;

  padding: 1rem;
  font-weight: 600;
  line-height: 1.5;
  @media ${device.mobileL} {
    letter-spacing: 0.2rem;
  }
  @media ${device.mobileXL} {
    padding: 2rem;
  }
  @media ${device.tablet} {
    line-line-height: 2;
  }
`
export const Title = styled(H1)`
  text-transform: uppercase;
  color: white;
  font-weight: 600;
  letter-spacing: 1rem;
  font-size: 2rem;
  margin: 1rem 0;

  @media ${device.tablet} {
    margin: 1rem 0;
  }
`
