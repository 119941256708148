import React from "react"
import { GlobalStyle } from "../theme/global-style"
import Header from "./header/header.component"
import Footer from "./footer/footer.component"
import styled from "styled-components"
import { device } from "../utils/responsive"
import "typeface-lato"
import "typeface-montserrat"

const Container = styled.div`
  margin: 0 auto;
  max-width: 960px;
  padding: 0;
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media ${device.tablet} {
    padding: 0 1.0875rem 0 1.45rem;
  }
`

const Layout = ({ children }) => {
  return (
    <>
      <GlobalStyle />
      <Header />
      <Container>
        <main id="page-wrap">{children}</main>
      </Container>

      <Footer />
    </>
  )
}

export default Layout
