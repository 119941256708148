const Types = {
  FETCH_FITOGRAM_DATA_START: "FETCH_FITOGRAM_DATA_START",
  FETCH_FITOGRAM_DATA_SUCCESS: "FETCH_FITOGRAM_DATA_SUCCESS",
  FETCH_FITOGRAM_DATA_FAILURE: "FETCH_FITOGRAM_DATA_FAILURE",

  GENERATING_DATA_START: "GENERATING_DATA_START",
  GENERATING_DATA_SUCCESS: "GENERATING_DATA_SUCCESS",
  GENERATING_DATA_FAILURE: "GENERATING_DATA_FAILURE",
  SET_YOGA_STYLES: "SET_YOGA_STYLES",

  FILTER_CLASSES_START: "FILTER_CLASSES_START",
  FILTER_CLASSES_ERROR: "FILTER_CLASSES_ERROR",
  FILTER_CLASSES_SUCCESS: "FILTER_CLASSES_SUCCESS",

  SET_TEACHERS_FILTER: "SET_TEACHERS_FILTER",
  SET_LOCATION_FILTER: "SET_LOCATION_FILTER",
  SET_CLASS_FILTER: "SET_CLASS_FILTER",
  RESET_FILTER: "RESET_FILTER",

  SET_SHOW_SCHEDULE: "SET_SHOW_SCHEDULE",
  SET_SCHEDULE_LOCATION: "SET_SCHEDULE_LOCATION",
}
export default Types
