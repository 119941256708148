import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import FacebookIcon from "../../images/facebook.svg"
import InstagramIcon from "../../images/instagram.svg"
import WhatsappIcon from "../../images/whatsapp.svg"

import AddressIcon from "../../images/address.svg"
import {
  StyledFooter,
  StyledIcon,
  Item,
  ItemGroup,
  GroupContainer,
  PrivacyContainer,
  PrivacyLink,
} from "./footer.styles"
const Footer = () => {
  const {
    mdx: { frontmatter },
  } = useStaticQuery(graphql`
    query GYContact {
      mdx(fileAbsolutePath: { regex: "/contact/" }) {
        frontmatter {
          title
          locations {
            Kreuzberg
            Prenzlauerberg
            Hasenheide
            Volkspark
            Pankow
          }
          socialMedia {
            facebookGroup
            facebookPage
            instagram
          }
        }
      }
    }
  `)

  return (
    <StyledFooter>
      <GroupContainer>
        {frontmatter.socialMedia && (
          <ItemGroup>
            {Object.keys(frontmatter.socialMedia).map((item, i) => {
              return (
                <Item
                  href={frontmatter.socialMedia[item]}
                  target="_blank"
                  key={item}
                >
                  <StyledIcon>
                    {item === "facebookGroup" || item === "facebookPage" ? (
                      <FacebookIcon></FacebookIcon>
                    ) : (
                      <InstagramIcon></InstagramIcon>
                    )}
                  </StyledIcon>
                  {item === "facebookGroup" ? (
                    <span> Facebook Group</span>
                  ) : item === "facebookPage" ? (
                    <span>Facebook Page</span>
                  ) : (
                    <span>Instagram</span>
                  )}
                </Item>
              )
            })}
          </ItemGroup>
        )}
        
        {frontmatter.locations && (
          <ItemGroup>
            {Object.keys(frontmatter.locations).map((item, i) => {
              return (
                <Item
                  href={frontmatter.locations[item]}
                  key={item}
                  target="_blank"
                >
                  <StyledIcon>
                    <AddressIcon width="30px"></AddressIcon>
                  </StyledIcon>
                  <span>Green Yoga {item}</span>
                </Item>
              )
            })}
          </ItemGroup>
        )}

        {frontmatter.whatsup && (
          <ItemGroup>
            {Object.keys(frontmatter.whatsup).map((item, i) => (
              <Item key={item} href={frontmatter.whatsup[item]} target="_blank">
                <StyledIcon>
                  <WhatsappIcon></WhatsappIcon>
                </StyledIcon>
                <span>{item} </span>
              </Item>
            ))}
          </ItemGroup>
        )}

      </GroupContainer>
      <div>
        <span role="img" aria-label="plant">
          🌱{" "}
        </span>
        Green Yoga © {new Date().getFullYear()}{" "}
        <span role="img" aria-label="plant">
          🌱{" "}
        </span>
        <PrivacyContainer>
          <PrivacyLink
            href="/privacy"
            target="_blank"
            rel="noreferrer noopener"
          >
            Privacy
          </PrivacyLink>
          <PrivacyLink href="/terms" target="_blank" rel="noreferrer noopener">
            Terms
          </PrivacyLink>
        </PrivacyContainer>
      </div>
    </StyledFooter>
  )
}
export default Footer
