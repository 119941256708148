import { useStaticQuery, graphql } from "gatsby"
import React from "react"

export const Logo = ({ className }) => {
  const {
    file: { publicURL },
  } = useStaticQuery(graphql`
    query LogoQuery {
      file(absolutePath: { regex: "/logo.png/" }) {
        publicURL
      }
    }
  `)

  return <img src={publicURL} alt="Green Yoga Online Logo" className={className}></img>
}

export default Logo
